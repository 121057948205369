<script>
import Layout from "@/router/layouts/template-pemohon";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Datepicker from "vue3-datepicker";
// import moment from 'moment';
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Datepicker,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboards",
          active: true,
        },
      ],
      // Session
      session: JSON.parse(localStorage.session),
      kbli_used: localStorage.kbli,
      // Filter
      start: null,
      end: null,
      status: "",
      filter_status: [
        {
          value: "draft",
          label: "Draft",
        },
        {
          value: "baru",
          label: "Baru",
        },
        {
          value: "menunggu perbaikan",
          label: "Menunggu Perbaikan",
        },
        {
          value: "dibatalkan",
          label: "Dibatalkan",
        },
        {
          value: "proses",
          label: "Proses",
        },
        {
          value: "selesai",
          label: "Selesai",
        },
      ],
      // variable Page Table
      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",
      // variabel ajukan
      master_perizinan: [],
      modalAjukan: false,
      perizinan_selected: [],
      perizinansub_selected: [],
      perusahaan_res_selected: [],
    };
  },
  mounted() {
    let self = this;
    // Select Chain Master Profinsi
    axios
      .get(
        process.env.VUE_APP_BACKEND_URL_VERSION +
          "referensi/all-perizinan-kbli",
        {
          params: {
            kbli: self.kbli_used,
            nib: this.session.nib,
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        }
      )
      .then((response) => {
        this.master_perizinan = response.data.data.referensi;
        Swal.close();
      })
      .catch((e) => {
        this.axiosCatchError = e.response.data.data;
        console.log(this.axiosCatchError);
        Swal.close();
      });
    // Select List Permohonan
    axios
      .get(process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/list", {
        params: {
          nib: this.session.nib,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
      .then((response) => {
        var response_data = response.data;
        var response_data_fix = response_data.data.permohonan;
        if (response_data.meta.code == 200) {
          self.loadingTable = false;
          self.dataTable = response_data_fix.data;
          self.pagingTable = response_data_fix.links;
          self.showDataToTable = response_data_fix.to;
          self.showDataFromTable = response_data_fix.from;
          self.totalDataTable = response_data_fix.total;
          self.currentTablePage = response_data_fix.current_page;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response_data_fix.data.message,
          });
        }
      })
      .catch((e) => {
        console.log(e.response);
        this.axiosCatchError = e.response.data.data;
        Swal.close();
      });
  },
  methods: {
    toPage(url) {
      let self = this;
      self.loadingTable = true;
      axios
        .get(url, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
          },
        })
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.loadingTable = false;
            self.dataTable = response_data_fix.data;
            self.pagingTable = response_data_fix.links;
            self.showDataToTable = response_data_fix.to;
            self.showDataFromTable = response_data_fix.from;
            self.totalDataTable = response_data_fix.total;
            self.currentTablePage = response_data_fix.current_page;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch((e) => {
          console.log(e.response);
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    showModalAjukan() {
      this.modalAjukan = true;
    },
    checkRedirect(from_select) {
      let self = this;
      var perizinan_selected = self.perizinan_selected;
      var perizinansub_selected = self.perizinansub_selected;
      if (from_select == "perizinan") {
        if (perizinan_selected.perizinansub.length == 0) {
          alert("tidak punya sub");
        }
      }
      if (from_select == "sub_perizinan") {
        if (perizinansub_selected) {
          if (perizinansub_selected.mps_mpj_id != 3) {
            this.modalAjukan = false;
            if (
              self.perizinansub_selected.is_konfig_form == "t" &&
              self.perizinansub_selected.is_konfig_dokumen == "t" &&
              self.perizinansub_selected.is_konfig_matrix == "t"
            ) {
              self.$router.push({
                name: "add-permohonan",
                query: {
                  perizinan_id: self.perizinan_selected.mp_id,
                  perizinan_sub_id: self.perizinansub_selected.mps_id,
                },
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Perizinan Belum Tersedia",
              });
            }
          }
        }
      }
      if (from_select == "perusahaan_respon") {
        if (self.perusahaan_res_selected) {
          this.modalAjukan = false;
          if (
            self.perizinansub_selected.is_konfig_form == "t" &&
            self.perizinansub_selected.is_konfig_dokumen == "t" &&
            self.perizinansub_selected.is_konfig_matrix == "t"
          ) {
            self.$router.push({
              name: "add-permohonan",
              query: {
                perizinan_id: self.perizinan_selected.mp_id,
                perizinan_sub_id: self.perizinansub_selected.mps_id,
                kode_izin: self.perusahaan_res_selected.kode_izin,
                id_izin: self.perusahaan_res_selected.idIzin,
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Perizinan Belum Tersedia",
            });
          }
        }
      }
    },
    searchTable() {
      let self = this;
      self.loadingTable = true;
      var config = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "permohonan/list",
        params: {
          nib: this.session.nib,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          var response_data_fix = response_data.data[0];
          if (response_data.meta.code == 200) {
            self.loadingTable = false;
            self.dataTable = response_data_fix.data;
            self.pagingTable = response_data_fix.links;
            self.showDataToTable = response_data_fix.to;
            self.showDataFromTable = response_data_fix.from;
            self.totalDataTable = response_data_fix.total;
            self.currentTablePage = response_data_fix.current_page;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data_fix.data.message,
            });
          }
        })
        .catch(function (e) {
          console.log(e.response);
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    deletePermohonan(status, p_id) {
      let self = this;

      let text_alert = "";
      if (status == "ENABLE") {
        text_alert = "Apakah anda yakin ingin menghapus data tersebut?";
      } else {
        text_alert = "Apakah anda yakin ingin mengembalikan data tersebut?";
      }

      Swal.fire({
        title: "Perhatian",
        text: text_alert,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then((result) => {
        if (result.isConfirmed) {
          self.loadingTable = true;
          Swal.fire({
            title: '<i class="fas fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });

          var config = {
            method: "put",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "permohonan/delete-permohonan",
            data: {
              permohonan_id: p_id,
              status: status,
            },
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + localStorage.access_token,
            },
          };
          axios(config).then((response) => {
            var response_data = response.data;
            if (response_data.meta.code != "200") {
              self.loadingTable = false;
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response_data.data.message,
              });
            } else {
              self.loadingTable = false;
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Data berhasil dihapus.",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                  timerInterval = setInterval(() => {
                    const content = Swal.getContent();
                    if (content) {
                      const b = content.querySelector("b");
                      if (b) {
                        b.textContent = Swal.getTimerLeft();
                      }
                    }
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  self.searchTable();
                }
              });
            }
          });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header top-radius-15 bg-success">
            <h4 class="my-0 text-center text-white"><b>Semua Permohonan</b></h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="btn btn-outline-success w-100"
                  v-on:click="showModalAjukan()"
                >
                  <i class="bx bx-plus"></i> Ajukan Permohonan
                </div>
              </div>
              <div class="col-md-12 my-3">
                <div class="row">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md">
                        <div class="form-group">
                          <b for="">Tgl Dari</b>
                          <datepicker
                            v-model="start"
                            lang="en"
                            class="form-control bg-white"
                          ></datepicker>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="form-group">
                          <b for="">Tgl Sampai</b>
                          <datepicker
                            v-model="end"
                            lang="en"
                            class="form-control bg-white"
                          ></datepicker>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <b for="">Status</b>
                          <v-select
                            :options="filter_status"
                            label="label"
                            v-model="status"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <br />
                    <div
                      class="btn btn-primary btn-sm"
                      v-on:click="filterPermohonan"
                    >
                      <i class="fa fa-filter"></i> Filter
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table mb-0 table-bordered table-condensed table-hover"
                  >
                    <thead class="bg-dark text-center text-white">
                      <tr>
                        <th style="width: 50px">No</th>
                        <th>Kode Permohonan</th>
                        <th>Perizinan</th>
                        <th>Permohonan</th>
                        <th>Status</th>
                        <th style="width: 175px">Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingTable">
                        <td colspan="6">
                          <i class="fas fa-spinner fa-spin"></i> Loading...
                        </td>
                      </tr>
                      <tr
                        v-for="(row_data, key_data) in dataTable"
                        :key="key_data"
                        v-else
                      >
                        <td class="text-center" style="vertical-align: middle">
                          <div v-if="key_data + 1 == 10">
                            {{ currentTablePage }}0.
                          </div>
                          <div v-else>
                            {{
                              currentTablePage - 1 != 0
                                ? currentTablePage - 1
                                : ""
                            }}{{ key_data + 1 }}.
                          </div>
                        </td>
                        <td class="text-middle-center">
                          <span v-if="row_data.p_kode">{{
                            row_data.p_kode
                          }}</span>
                          <span v-else>Data Tidak Tersedia</span>
                        </td>
                        <td>
                          <table class="table table-sm table-borderless">
                            <tr>
                              <td style="width: 150px">Jenis Perizinan</td>
                              <td style="width: 15px">:</td>
                              <th>
                                <span
                                  v-if="
                                    row_data?.perizinan?.perizinanjenis
                                      ?.mpj_nama
                                  "
                                  >{{
                                    row_data.perizinan.perizinanjenis.mpj_nama
                                  }}</span
                                >
                              </th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Perizinan</td>
                              <td style="width: 15px">:</td>
                              <th>
                                <span v-if="row_data?.perizinan?.mp_nama">{{
                                  row_data.perizinan.mp_nama
                                }}</span>
                              </th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Sub Perizinan</td>
                              <td style="width: 15px">:</td>
                              <th>
                                <span v-if="row_data?.perizinansub?.mps_nama">{{
                                  row_data.perizinansub.mps_nama
                                }}</span>
                              </th>
                            </tr>
                          </table>
                        </td>
                        <td>
                          <table class="table table-sm table-borderless">
                            <tr>
                              <td style="width: 150px">Nomor Surat</td>
                              <td style="width: 15px">:</td>
                              <th>{{ row_data.p_nomor_surat_permohonan }}</th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Tanggal Surat</td>
                              <td style="width: 15px">:</td>
                              <th>{{ row_data.p_tanggal_surat_permohonan }}</th>
                            </tr>
                            <tr>
                              <td style="width: 150px">Perihal</td>
                              <td style="width: 15px">:</td>
                              <th>{{ row_data.p_perihal }}</th>
                            </tr>
                          </table>
                        </td>
                        <td class="text-middle-center">
                          <badge
                            v-if="row_data.p_status_kategori == 'Draft'"
                            class="badge bg-warning"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge
                            v-else-if="row_data.p_status_kategori == 'Selesai'"
                            class="badge bg-success"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge
                            v-else-if="
                              row_data.p_status_kategori == 'Perbaikan'
                            "
                            class="badge bg-warning"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge
                            v-else-if="row_data.p_status_kategori == 'Ditolak'"
                            class="badge bg-warning"
                            >{{ row_data.p_status_kategori }}</badge
                          >
                          <badge v-else class="badge bg-info">{{
                            row_data.status_permohonan
                          }}</badge>
                        </td>
                        <td class="text-middle-center">
                          <router-link
                            :to="{
                              name: 'pemohon-edit-permohonan',
                              params: { permohonan_id: row_data.p_id },
                            }"
                            v-if="row_data.p_status_kategori == 'Draft'"
                            class="btn bg-warning text-white w-100"
                            ><i class="bx bxs-edit-alt"></i> Edit</router-link
                          >
                          <router-link
                            :to="{
                              name: 'pemohon-edit-perbaiki-permohonan',
                              params: { permohonan_id: row_data.p_id },
                            }"
                            v-else-if="
                              row_data.p_status_kategori == 'Perbaikan'
                            "
                            class="btn bg-warning text-white w-100"
                            ><i class="bx bxs-edit-alt"></i>
                            Perbaiki</router-link
                          >
                          <router-link
                            :to="{
                              name: 'pemohon-detail-permohonan',
                              params: { permohonan_id: row_data.p_id },
                            }"
                            v-else
                            class="btn bg-info text-white w-100"
                            ><i class="fa fa-eye"></i> Detail</router-link
                          >
                          <button
                            v-if="row_data.p_status_kategori == 'Draft'"
                            type="button"
                            class="btn bg-danger text-white w-100 mt-1"
                            v-on:click="
                              deletePermohonan(row_data.is_aktif, row_data.p_id)
                            "
                          >
                            <i class="fa fa-times"></i> Hapus
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="pt-4">
                  Show {{ showDataFromTable }}-{{ showDataToTable }} From
                  {{ totalDataTable }}
                </div>
              </div>
              <div class="col-md-6">
                <div style="float: right !important">
                  <nav aria-label="pagination" class="mt-3">
                    <ul class="pagination justify-content-start">
                      <li
                        v-for="(page, key_page) in pagingTable"
                        v-bind:class="{ active: page.active }"
                        :key="key_page"
                        class="page-item"
                      >
                        <div
                          v-on:click="toPage(page.url)"
                          class="page-link c-page-link-number"
                        >
                          <div v-html="page.label"></div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalAjukan" hide-footer centered header-class="border-0">
      <div class="text-center mb-4">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Pilih Perizinan"
              label-for="formrow-jenis-perizinan-pilihan"
            >
              <v-select
                id="formrow-jenis-perizinan-pilihan"
                :options="master_perizinan"
                label="mp_nama"
                v-model="perizinan_selected"
                placeholder="Pilih Perizinan"
                @option:selected="checkRedirect('perizinan')"
              ></v-select>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
              class="mb-3"
              label="Pilih Sub Perizinan"
              label-for="formrow-perizinan-pilihan"
            >
              <v-select
                id="formrow-perizinan-pilihan"
                :options="perizinan_selected.perizinansub"
                label="mps_nama"
                v-model="perizinansub_selected"
                :disabled="!perizinan_selected.perizinansub"
                placeholder="Pilih Sub Perizinan"
                @option:selected="checkRedirect('sub_perizinan')"
              >
                <template v-slot:option="option">
                  {{ option.mps_nama }}
                  <span
                    v-if="
                      option.is_konfig_form == 't' &&
                      option.is_konfig_dokumen == 't' &&
                      option.is_konfig_matrix == 't'
                    "
                    >(Tersedia)</span
                  >
                  <span v-else>(Belum Tersedia)</span>
                </template>
                <template #selected-option="option">
                  {{ option.mps_nama }}
                  <span
                    v-if="
                      option.is_konfig_form == 't' &&
                      option.is_konfig_dokumen == 't' &&
                      option.is_konfig_matrix == 't'
                    "
                    >(Tersedia)</span
                  >
                  <span v-else>(Belum Tersedia)</span>
                </template>
              </v-select>
            </b-form-group>
          </div>
          <div class="col-md-12" v-if="perizinansub_selected.mps_mpj_id == 3">
            <b-form-group
              class="mb-3"
              label="Pilih Id Izin"
              label-for="formid_izin"
            >
              <v-select
                id="formid_izin"
                :options="perizinansub_selected.perusahaan_respon"
                label="mps_nama"
                v-model="perusahaan_res_selected"
                :disabled="!perizinansub_selected"
                placeholder="Pilih Id Izin"
                @option:selected="checkRedirect('perusahaan_respon')"
              >
                <template v-slot:option="option">
                  {{ option.idIzin }} - {{ option.idProyek }}
                </template>
                <template #selected-option="option">
                  {{ option.idIzin }} - {{ option.idProyek }}
                </template>
              </v-select>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
